import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { notification, Slider, Spin } from 'antd';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { SoilColorObj } from '@utils/SoilColorObj';
import { findPointIntersect, updateLegendDataExtended } from '@utils/soilTypes';
import { fieldMetaList } from '@reducer/FieldsMetaData/selector';
import { getSelectedOrganizationCountryDetails } from '@selectors/headerSelectors';

import FieldsRecommendationService from '../../../services/FieldsRecommendationService';
import { SoilLayerLegend } from './SoilLayerLegend';

let poly = [];
export const SoilLayerWidget = ({
  propertyFields,
  mapInstance,
  closeSoilLayerWidget,
  topPosition
}) => {
  const [legends, setLegends] = useState({});
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const fieldInfoData = useSelector(fieldMetaList);
  const currentOrg = useSelector(getSelectedOrganizationCountryDetails);
  const legendData = Object.keys(legends);

  const onFieldOpacityChange = (val) => {
    if (poly) {
      poly.forEach((pItem) => {
        if (pItem) {
          pItem.setStyle({ opacity: val / 100, fillOpacity: val / 100 });
        }
      });
    }
  };

  const formatter = (value) => {
    return `${value}%`;
  };
  /**
   * Ensure map and legend include all soil type data for selected fields with soil types
   * @param {{ id: any, declared_area: any, geometry?: any, properties?: { uniqueSoilType?: any }, type?: string }[]} soilTypeFields List of fields that already have soil type assigned
   */
  const updateMapAndLegendWithSoilTypes = (soilTypeFields) => {
    soilTypeFields.forEach((soilTypeField) => {
      if (!soilTypeField.properties || !soilTypeField.properties.uniqueSoilType) {
        soilTypeField.properties = {};
      }
      soilTypeField.properties.uniqueSoilType = fieldInfoData[soilTypeField.id].soilType;
      if (!soilTypeField.type) {
        soilTypeField.type = 'Feature';
      }
      addGridSquareToMap(soilTypeField);
      updateLegendDataExtended(
        legendData,
        soilTypeField,
        soilTypeField.declared_area,
        t('Updated'),
        currentOrg
      );
    });
    setLegends(legendData);
    setLoading(false);
  };
  const addGridSquareToMap = (item) => {
    const soilTypeColor = item.properties?.uniqueSoilType
      ? SoilColorObj[item.properties.uniqueSoilType]
      : 'gray';
    const maplayer = L.geoJSON(item, {
      style: {
        fillColor: soilTypeColor,
        weight: 1,
        opacity: 1,
        fillOpacity: 1,
        fill: true,
        color: soilTypeColor
        // strokeWidth: 1
      }
    }).addTo(mapInstance);
    poly.push(maplayer);
  };
  const resetLegendsAndPoly = () => {
    setLegends({});
    if (poly.length > 0) {
      poly.forEach((item) => {
        item.remove();
      });
      poly = [];
    }
  };

  /**
   * Fetch and calculate the soil type for all fields that don't have known soil type
   * @param {{ id: any, declared_area: any, geometry?: any, properties?: { uniqueSoilType?: any }, type?: string }[]} noneSoilTypeFields All fields without soil type already assigned
   * @param {{ id: any, declared_area: any, geometry?: any, properties?: { uniqueSoilType?: any }, type?: string }[]} soilTypeFields List of fields that already have soil type assigned
   */
  const findSoilTypesForFields = (noneSoilTypeFields, soilTypeFields) => {
    let totalAreaofField = noneSoilTypeFields.reduce(
      (total, field) => total + Number(field.declared_area),
      0
    );
    const payLoadData = noneSoilTypeFields
      .filter((field) => !!field && field.geometry)
      .map((field, index) => ({
        id: index + 1,
        geometry: field.geometry,
        acres: field.declared_area
      }));

    const payload = {
      fields: payLoadData,
      cropRegion: 'SunflowerEame'
    };

    FieldsRecommendationService.fetchSoilData(payload)
      .then((response) => {
        const data = response.data;
        if (data && data.failed.length === 0) {
          if (data.successful) {
            const resolution = data.fields_resolution / 2;
            data.successful.forEach((item, index) => {
              noneSoilTypeFields[index].type = 'Feature';
              const features = item.grid.map((point) =>
                findPointIntersect(point, resolution, noneSoilTypeFields[index].geometry, 'red')
              );
              features.forEach((itemParam) => {
                addGridSquareToMap(itemParam);
                updateLegendDataExtended(
                  legendData,
                  itemParam,
                  totalAreaofField,
                  t('Predicted'),
                  currentOrg
                );
              });
            });
          }

          const totalGridAcerss = Object.values(legendData).reduce(
            (total, childItem) => total + Number(childItem.totalArea),
            0
          );
          const totalGridAcers = Number(totalGridAcerss.toFixed(2));

          totalAreaofField = Number(totalAreaofField.toFixed(2));

          if (totalGridAcers < totalAreaofField) {
            let diff = totalAreaofField - totalGridAcers;
            // if multiple entries in legendData, divide the diff equally
            if (Object.keys(legendData).length > 1) {
              diff = diff / Object.keys(legendData).length;
            }

            Object.keys(legendData).forEach((childItem) => {
              legendData[childItem].totalArea = legendData[childItem].totalArea + diff;
            });
          }

          Object.keys(legendData).forEach((childItem) => {
            legendData[childItem].totalCl = totalGridAcerss;
          });
          updateMapAndLegendWithSoilTypes(soilTypeFields);
        } else {
          setLoading(false);
          notification.error({
            message: t('Something went wrong'),
            placement: 'topRight'
          });
        }
      })
      .catch(() => {
        setLoading(false);
        notification.error({
          message: t('Something went wrong'),
          placement: 'topRight'
        });
      });
  };

  /**
   * Ensure all selected fields (at least one) have soil type assigned
   * @param {{ id: any, declared_area: any, geometry?: any, properties?: { uniqueSoilType?: any }, type?: string }[]} fieldlistParam list of fields
   */
  const calculateSoilDataForFields = (fieldlistParam) => {
    // selected fields filter with metadata
    const soilTypeFields = fieldlistParam.filter(
      (field) => fieldInfoData[field.id] && fieldInfoData[field.id].soilType
    );
    const noneSoilTypeFields = fieldlistParam.filter(
      (field) => !fieldInfoData[field.id] || !fieldInfoData[field.id].soilType
    );

    // all selected fields have soil type assigned
    if (soilTypeFields.length > 0 && noneSoilTypeFields.length === 0) {
      updateMapAndLegendWithSoilTypes(soilTypeFields);
    }

    // some fields do not have soil type assigned
    if (noneSoilTypeFields.length > 0) {
      findSoilTypesForFields(noneSoilTypeFields, soilTypeFields);
    }
  };

  useEffect(() => {
    resetLegendsAndPoly();
    setLoading(true);
    calculateSoilDataForFields(propertyFields);
    return function clean() {
      resetLegendsAndPoly();
    };
  }, []);

  const closeLegends = () => {
    closeSoilLayerWidget();
    resetLegendsAndPoly();
  };
  return (
    <div
      style={{
        zIndex: 1001,
        position: 'absolute',
        left: '12px',
        top: topPosition ?? '7px',
        paddingRight: '5px',
        marginLeft: 'calc(100% - 300px)',
        background: '#fff',
        borderRadius: '8px',
        minWidth: '280px'
      }}
    >
      <h4
        style={{
          padding: '8px 30px',
          borderBottom: '1px solid lightgray',
          borderTopRightRadius: 8,
          borderTopLeftRadius: 8
        }}
      >
        {t('Soil Information')}
      </h4>
      <button
        style={{
          position: 'absolute',
          right: '8px',
          top: '0',
          fontSize: '25px',
          cursor: 'pointer',
          background: 'none',
          border: 'none'
        }}
        onClick={closeLegends}
      >
        x
      </button>
      {loading ? (
        <div style={{ height: '120px', display: 'flex', flexDirection: 'column' }}>
          <Spin />
        </div>
      ) : (
        <>
          <p
            style={{
              padding: '0px 30px',
              margin: 0,
              lineHeight: '10px',
              color: '#2F3031',
              fontWeight: '400',
              fontSize: 11
            }}
          >
            {t('Adjust Opacity')}
          </p>
          <Slider tipFormatter={formatter} defaultValue={100} onChange={onFieldOpacityChange} />
          <ul style={{ padding: '0' }}>
            {Object.keys(legends)
              .sort((itemA, itemB) => itemA - itemB)
              .map((item, index) => {
                let BGColor = `${SoilColorObj[item] ? SoilColorObj[item] : 'gray'}`;

                return (
                  <SoilLayerLegend
                    key={index}
                    t={t}
                    item={item}
                    index={index}
                    BGColor={BGColor}
                    legends={legends}
                  />
                );
              })}
            {localStorage.getItem('showValues') !== null && (
              <>
                <li>
                  <br /> total ara: {Object.values(legends)[0].ttArea}
                </li>
                <li>
                  <br /> total ara: {Number(Object.values(legends)[0].totalCl).toFixed(2)}
                </li>
              </>
            )}
          </ul>
        </>
      )}
    </div>
  );
};

SoilLayerWidget.propTypes = {
  closeSoilLayerWidget: PropTypes.func,
  mapInstance: PropTypes.any,
  propertyFields: PropTypes.any,
  topPosition: PropTypes.any
};

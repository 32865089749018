import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { useImmer } from 'use-immer';
import { MapAdditionalServiceWidget } from './Components/MapAdditionalServiceWidget';
import ImageryWidget from '../Imagery';
import SoilLayerWidget from '../SoilLayer';

export const MapAdditionalServices = ({
  logAmplitudeEvent,
  fields,
  propertyId,
  mapInstance,
  propertyFieldsCount,
  propertyCount,
  isFieldUpdate,
  topPosition
}) => {
  const [mapState, setMapState] = useImmer({
    isImageryOpen: false,
    isAdditionalServicesOpen: true,
    isElevationLayer: false,
    isSoilLayerOpen: false
  });
  useEffect(() => {
    if (isFieldUpdate) {
      setMapState((draft) => {
        draft.isImageryOpen = false;
        draft.isSoilLayerOpen = false;
        draft.isAdditionalServicesOpen = true;
      });
    }
  }, [isFieldUpdate]);
  return (
    <>
      {mapState.isImageryOpen && (
        <ImageryWidget
          topPosition={topPosition}
          propertyFields={fields}
          propertyId={propertyId}
          mapInstance={mapInstance}
          closeImageryWidget={() => {
            setMapState((draft) => {
              draft.isImageryOpen = false;
              draft.isAdditionalServicesOpen = true;
            });
          }}
        />
      )}
      {mapState.isSoilLayerOpen && (
        <SoilLayerWidget
          topPosition={topPosition}
          propertyFields={fields}
          propertyId={propertyId}
          mapInstance={mapInstance}
          closeSoilLayerWidget={() => {
            setMapState((draft) => {
              draft.isSoilLayerOpen = false;
              draft.isAdditionalServicesOpen = true;
            });
          }}
        />
      )}
      {mapState.isAdditionalServicesOpen && (
        <MapAdditionalServiceWidget
          logAmplitudeEvent={logAmplitudeEvent}
          setMapState={setMapState}
          fields={fields}
          propertyFieldsCount={propertyFieldsCount}
          propertyId={propertyId}
          propertyCount={propertyCount}
          mapInstance={mapInstance}
          topPosition={topPosition}
        />
      )}
    </>
  );
};

MapAdditionalServices.propTypes = {
  fields: PropTypes.any,
  isFieldUpdate: PropTypes.any,
  logAmplitudeEvent: PropTypes.any,
  mapInstance: PropTypes.any,
  propertyCount: PropTypes.any,
  propertyFieldsCount: PropTypes.any,
  propertyId: PropTypes.any,
  topPosition: PropTypes.any
};

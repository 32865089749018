import PropTypes from 'prop-types';
import React from 'react';
import { useSelector } from 'react-redux';
import { ControlOutlined } from '@ant-design/icons';
import { currentFeatureSet, isEnabled } from '@selectors/featureSet';
import { convertAreaForUOM } from '@utils/getUOMConversions';
import { SoilLayerStyle } from '../styles';

export const SoilLayerLegend = ({ t, item, index, legends, BGColor }) => {
  const featureSet = useSelector(currentFeatureSet);
  return (
    <li
      key={index}
      style={{
        listStyle: 'none',
        margin: ' 10px 0',
        padding: '0 10px',
        borderBottom: '1px solid lightgray'
      }}
    >
      <div>
        <div
          style={{
            height: '14px',
            width: '14px',
            float: 'left',
            margin: ' 0 10px 0 0',
            borderRadius: '100%',
            backgroundColor: BGColor
          }}
        />
        <div>
          <p
            style={{
              margin: 0,
              lineHeight: '13px',
              color: '#2F3031',
              fontWeight: '400'
            }}
          >
            {item !== ' ' ? t(`${item}`) : 'N/A'}
          </p>
          <SoilLayerStyle>
            <label style={{ fontWeight: '100' }}>
              <ControlOutlined style={{ padding: '0px 8px 0 0', color: '#9fa1a2a8' }} />
              {convertAreaForUOM({
                input: `${Number(legends[item].totalArea).toFixed(2)} ha`,
                t: t,
                featureFlag: isEnabled('UnitOfMeasurement', featureSet)
              })}
              <span style={{ padding: '0 20px', fontWeight: 500, fontSize: '15px' }}>
                {legends[item].status}
              </span>
            </label>
          </SoilLayerStyle>
        </div>
      </div>
    </li>
  );
};

SoilLayerLegend.propTypes = {
  BGColor: PropTypes.any,
  index: PropTypes.any,
  item: PropTypes.string,
  legends: PropTypes.any,
  t: PropTypes.func
};

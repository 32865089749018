import locale_en from 'antd/es/locale/en_US';
import locale_pt from 'antd/es/locale/pt_BR';
import locale_es from 'antd/es/locale/es_ES';
import locale_it from 'antd/es/locale/it_IT';
import locale_bg from 'antd/es/locale/bg_BG';
import locale_fr from 'antd/es/locale/fr_FR';
import locale_de from 'antd/es/locale/de_DE';
import locale_hu from 'antd/es/locale/hu_HU';
import locale_pl from 'antd/es/locale/pl_PL';
import locale_ro from 'antd/es/locale/ro_RO';
import locale_ru from 'antd/es/locale/ru_RU';
import locale_uk from 'antd/es/locale/uk_UA';
import moment from 'moment';
import 'moment/locale/pt-br';
import 'moment/locale/es';

let languageCode = {
  pt: 'pt',
  es: 'es',
  en: 'en',
  it: 'it',
  bg: 'bg',
  fr: 'fr',
  de: 'de',
  hu: 'hu',
  pl: 'pl',
  ro: 'ro',
  ru: 'ru',
  uk: 'uk'
  // hi: 'hi'
};

export const getLocale = () => {
  let selectedLanguage = localStorage.getItem('i18nextLng') || 'en';
  let locale = {};
  moment.locale(languageCode[selectedLanguage]);
  switch (selectedLanguage) {
    case 'pt':
      locale = locale_pt;
      break;
    case 'es':
      locale = locale_es;
      break;
    case 'it':
      locale = locale_it;
      break;
    case 'bg':
      locale = locale_bg;
      break;
    case 'hu':
      locale = locale_hu;
      break;
    case 'fr':
      locale = locale_fr;
      break;
    case 'de':
      locale = locale_de;
      break;
    case 'pl':
      locale = locale_pl;
      break;
    case 'ro':
      locale = locale_ro;
      break;
    case 'ru':
      locale = locale_ru;
      break;
    case 'uk':
      locale = locale_uk;
      break;
    // case 'hi':
    //   locale = locale_hi;
    //   break;
    default:
      locale = locale_en;
  }
  return locale;
};

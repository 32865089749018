import React, { useRef, useState, useEffect } from 'react';
import { Spin, Button } from 'antd';
import GoogleLayer from '../features/GoogleMap/GoogleLayer';
import { SearchOutlined } from '@ant-design/icons';

import { useMapContext } from '../features/GoogleMap/MapProvider';
import { MapContainer, FeatureGroup } from 'react-leaflet';
import { selectedFieldIdsUpdate } from '../features/GoogleMap/helpers/selectedFieldIdsUpdate';
import { validateFieldsGeometry } from '../features/GoogleMap/helpers/vaidateFieldsGeometry';
import { forceUpdateMap } from '../features/GoogleMap/helpers/forceUpdateMap';
import { updateMarkerAndFields } from '../features/GoogleMap/helpers/updateMarkerAndFields';
import { updateCurrentZoom } from '../features/GoogleMap/helpers/updateCurrentZoom';
import Markers from '../features/GoogleMap/Markers';
import { MapFieldsLayer } from '../features/GoogleMap/MapFieldsLayer';
import { MapEvents } from '../features/GoogleMap/MapEvents';
import { MapStyle } from '../features/GoogleMap/styles';
import { MapAdditionalServices } from '../features/MapAdditionalServices';
import { mapBounds } from '../features/GoogleMap/helpers/mapBounds';
import PropTypes from 'prop-types';
export const MapLayout = ({ currentPage }) => {
  const { setCurrentMapInstance, currentProps, mapCurrentInstance } = useMapContext();
  const mapRef = useRef(null);
  const groupRef = useRef(null);
  useEffect(() => {
    if (mapRef.current) {
      setCurrentMapInstance(mapRef.current);
    }
  }, [setCurrentMapInstance, mapRef.current, currentProps]);

  const {
    isMarkers,
    fields,
    coords,
    isFields,
    setMapInstance = () => {},
    onClick,
    mapId = 'map-id',
    isReloadMap,
    isMultiFieldPage,
    selectedFieldIds,
    mapMarkers = [],
    canPopupOpen,
    // eslint-disable-next-line no-empty-function
    onMapClick = () => {},
    isSpotCheckPage,
    isDoubleReviewPage,
    isFieldMetaMap,
    isAllFieldList,
    editFieldPage,
    isLoading,
    setDetectFields = () => {},
    isFieldsPresent = false,
    mapZoom,
    t = () => {}
  } = currentProps;
  const mapCenter = coords && coords.length > 0 ? coords : null;
  if (localStorage.getItem('DEBUG')) {
    // eslint-disable-next-line no-console
    console.log({ currentProps }, ' currentProp map =====>');
  }
  const [mapFields, setMapFields] = useState([]);
  const [mapState, setMapState] = useState({
    currentIndex: 0,
    totalIndex: 2
  });
  const [mapFieldIds, setMapFieldIds] = useState([]);
  const [isUpdate, setIsUpdate] = useState(true);
  const [currentZoom, setCurrentZoom] = useState(0);
  const [isMarkerUpdate, setIsMarkerUpdate] = useState(true);
  useEffect(() => {
    selectedFieldIdsUpdate({ setIsMarkerUpdate, selectedFieldIds, mapRef });
  }, [JSON.stringify(selectedFieldIds)]);

  useEffect(() => {
    validateFieldsGeometry({
      setMapFields,
      fields,
      setIsUpdate,
      mapRef,
      setMapState,
      isFields,
      mapState
    });
  }, [JSON.stringify(fields)]);

  useEffect(() => {
    forceUpdateMap();
  }, [isReloadMap]);

  useEffect(() => {
    return function cleanup() {
      setIsMarkerUpdate(true);
    };
  }, [currentPage]);
  useEffect(() => {
    updateMarkerAndFields({
      mapMarkers,
      mapFields,
      mapRef,
      groupRef,
      isSpotCheckPage,
      isMultiFieldPage,
      fields,
      setMapInstance,
      mapFieldIds,
      setMapFieldIds,
      setMapFields,
      isFieldMetaMap,
      isAllFieldList,
      editFieldPage
    });
  }, [mapMarkers, JSON.stringify(mapFields), JSON.stringify(fields), mapFieldIds, mapRef]);
  useEffect(() => {
    if (typeof setMapInstance === 'function') {
      setTimeout(() => {
        setMapInstance(mapRef.current);
      }, 1000);
    }
  }, []);
  const spinnerStyle = {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(255, 255, 255, 0.7)',
    zIndex: 1000
  };

  const searchStyle = {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    marginTop: '20px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-start',
    maxHeight: '50px',
    marginLeft: '20px',
    marginRight: '20px',
    zIndex: 1000
  };

  useEffect(() => {
    if (isMultiFieldPage && !canPopupOpen && mapRef && mapRef.current) {
      mapRef.current.closePopup();
    }
  }, [canPopupOpen]);
  const onChangeZoom = (zoom) => {
    updateCurrentZoom({
      mapRef,
      setCurrentZoom,
      isMultiFieldPage,
      setIsMarkerUpdate,
      zoom,
      isSpotCheckPage,
      isAllFieldList
    });
  };
  const { extraBounds } = mapBounds(mapMarkers);
  const { center } = mapState;
  let extraCenter = {};
  if (center) {
    extraCenter = {
      center
    };
  }
  if (mapCenter && !center) {
    extraCenter = {
      center: mapCenter
    };
  }
  return (
    <MapStyle className={mapId.toLowerCase()}>
      <MapContainer
        ref={mapRef}
        id={mapId}
        center={[49.48242187500001, 41.376808565702355]}
        zoom={10}
        maxZoom={20}
        style={{ height: `100%` }}
      >
        <GoogleLayer />
        <FeatureGroup ref={groupRef}>
          {isMarkers &&
          isMarkerUpdate &&
          (!isMultiFieldPage || (isMultiFieldPage && currentZoom < 12)) ? (
            <Markers {...currentProps} selectedFieldIds={selectedFieldIds} />
          ) : null}
          {isFields ? (
            <MapFieldsLayer
              mapRef={mapRef}
              mapFields={mapFields}
              mapFieldIds={mapFieldIds}
              update={isUpdate}
              mapState={mapState}
              setMapState={setMapState}
              onClick={onClick}
              canPopupOpen={canPopupOpen}
              isDoubleReviewPage={isDoubleReviewPage}
              selectedFieldIds={selectedFieldIds}
              {...currentProps}
            />
          ) : null}
        </FeatureGroup>
        <MapEvents
          center={extraCenter}
          extraBounds={extraBounds}
          updateCurrentZoom={onChangeZoom}
          onMapClick={onMapClick}
        />
        {currentProps.sessionData && currentProps.isMultiFieldPage && (
          <MapAdditionalServices
            logAmplitudeEvent={currentProps.logAmplitudeEvent}
            propertyId={currentProps.propertyId}
            propertyCount={currentProps.propertyCount}
            propertyFieldsCount={currentProps.PropertyFieldsCount}
            fields={currentProps.sessionData ? currentProps.sessionData.regionFields : []}
            mapInstance={mapCurrentInstance}
            isFieldUpdate={currentProps.isFieldUpdate}
            topPosition='2px'
          />
        )}
        {currentProps.isAllFieldList && (
          <MapAdditionalServices
            propertyId={currentProps.propertyId}
            propertyCount={currentProps.propertyCount}
            propertyFieldsCount={currentProps.propertyFieldsCount}
            fields={currentProps.selectedFields}
            mapInstance={mapCurrentInstance}
            isFieldUpdate={currentProps.isFieldUpdate}
          />
        )}
        {isLoading && mapId === 'detectFieldPageMap' && (
          <div style={spinnerStyle}>
            <Spin size='large' />
          </div>
        )}
        {(currentZoom < 14 || mapZoom < 14) && mapId === 'detectFieldPageMap' && (
          <div style={searchStyle}>
            <Button type='primary' onClick={() => setDetectFields()} icon={<SearchOutlined />}>
              {t('Zoom in to identify fields')}
            </Button>
          </div>
        )}
        {currentZoom > 23 && isFieldsPresent && mapId === 'detectFieldPageMap' && (
          <div style={searchStyle}>
            <Button type='primary'>{t('Select Shape')}</Button>
          </div>
        )}
      </MapContainer>
    </MapStyle>
  );
};
MapLayout.propTypes = {
  currentPage: PropTypes.string
};

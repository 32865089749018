export class ApiCommunicationError {
  constructor(api: string, category: string, code: number, message: string) {
    this.api = api;
    this.category = category;
    this.code = code;
    this.message = message;
  }

  api: string;
  category: string;
  code: number;
  message: string;
}

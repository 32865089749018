import styled from 'styled-components';
import { Collapse, Drawer, Menu } from 'antd';

export const StyledSubMenu = styled(Menu.SubMenu)`
  background: #fff;
  font-weight: 500;
  color: #2f3031;
  margin-top: ${(props) => (props.isiphone ? '3%' : '0')};
  .ant-menu-submenu > .ant-menu {
    background-color: #fff;
  }
`;

export const StyledMenuItem = styled(Menu.Item)`
  color: #2f3031;
  padding-left: 14px !important;
  font-weight: normal;
  margin: 0px !important;
`;

export const StyledDivider = styled(Menu.Divider)`
  margin: 0px 10px 0px 6px !important;
  min-width: 90%;
  width: 90%;
  background-color: #e8eaed !important;
`;

export const StyleDrawer = styled(Drawer)`
  .ant-drawer-right.ant-drawer-open .ant-drawer-content-wrapper {
    box-shadow: none !important;
  }
  .ant-drawer-content {
    overflow: hidden;
  }
  .ant-drawer-wrapper-body {
    height: 350px;
  }
  .ant-drawer-title {
    color: #2f3031;
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
  }
  .ant-drawer-close {
    color: #515253;
  }
  .ant-drawer-body {
    padding: 0;
    height: 100%;
  }
  .ant-card {
    /* height: 100%; */
  }
  .ant-drawer-content {
    border-radius: 7px;
  }
  .weather-drawer-class .ant-drawer-content-wrapper {
    width: 274px;
  }
  .ant-collapse {
    background-color: #ffffff;
  }
  .ant-collapse-content-box {
    padding: 5px 10px;
    overflow-y: auto;
    overflow-x: hidden;
    height: 110px;
  }
  .ant-collapse-content .ant-collapse-content-active {
    height: 140px;
  }
  .ant-collapse-item:last-child > .ant-collapse-content {
    width: 100%;
    padding: 0;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
  }
  .ant-drawer-wrapper-body {
    overflow: hidden;
  }
  .ant-collapse-icon-position-right > .ant-collapse-item > .ant-collapse-header {
    padding: 10px 15px;
    /* background-color: #238c4e; */
    /* color: white; */
    border-radius: 0;
    margin: 0;
  }
  .ant-drawer-content {
    background-color: transparent;
  }
  .ant-drawer-header {
    padding: 8px 24px;
  }
  .ant-table-tbody > tr > td {
    font-size: 10px;
    padding: 5px 5px;
    text-align: center;
  }
  .ant-table-thead > tr > th {
    font-size: 12px;
    text-align: center;
  }
  .ant-table-pagination.ant-pagination {
    margin: 5px 0;
  }
  .ant-table-small
    > .ant-table-content
    > .ant-table-scroll
    > .ant-table-body
    > table
    > .ant-table-tbody
    > tr
    > td {
    padding: 5px 0px;
  }
  .ant-collapse {
    border: 0;
  }
  .ant-drawer-close {
    top: -6px;
    right: -6px;
    height: 47px;
  }
  .ant-table-small > .ant-table-content .ant-table-header {
    overflow: hidden !important;
    background-color: #867d7d38;
  }
  .ant-table-small {
    border: 0;
    border-radius: 0;
  }
  p {
    margin: 0;
  }
  /*****Card Css */
  .ant-card-head {
    padding: 5px 10px;
  }
  .ant-card-head-title {
    padding: 0;
  }
  .ant-card-body {
    padding: 0;
  }
`;

export const SoilZonePoupDiv = styled.div`
  display: flex;
  line-height: 10px;
  .colorBall {
    width: 10px;
    height: 10px;
    /* border: 1px solid; */
    border-radius: 100%;
    margin: 0 10px 0 0;
  }
`;

export const AdditionalServiceWidgetStyle = styled.div`
  position: absolute;
  top: ${(props) => (props.topPosition ? props.topPosition : `7px`)};
  right: 5px;
  min-width: ${(props) => props.minWidth || `230px`};
  box-sizing: border-box;
  z-index: 999;
  background: #fff;
  border-radius: 8px !important;
  .ant-slider {
    margin: 5px 30px;
  }
  .anticon-spin {
    color: #00b5d8;
    font-size: 30px;
  }
  .map-wheather-menu {
    background: #fff;
    border-radius: 8px;
  }
  .map-wheather-menu ul,
  ul {
    border-radius: 8px !important;
  }
  .map-wheather-menu * {
    background-color: #fff;
  }

  .wheather-submenu {
    border-radius: 8px;
    width: 98%;
    margin: auto;
  }

  .wheather-submenu .ant-menu-submenu-title {
    padding-left: 15px !important;
  }
  .ant-menu-inline .ant-menu-item-selected::after {
    opacity: 0;
  }

  .map-timeline-container-and-weather-responsive {
    width: 700px !important;
    left: 41% !important;
  }
  .ant-drawer-header {
    padding-left: 16px;
  }
  #weather-drawer .ant-drawer-wrapper-body {
    height: 90%;
  }
  .farm-name {
    width: 60%;
  }
  .ant-menu-submenu-arrow {
    display: none;
  }
`;

export const CollapseStyle = styled(Collapse)`
  .ant-collapse-content-box {
    padding: 5px 10px;
    overflow-y: auto;
    overflow-x: hidden;
    height: 140px;
  }
`;

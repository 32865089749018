/* tslint:disable */
/* eslint-disable */
import moment from 'moment';
import { axiosGetUnstable } from '@utils/unstableRequest';
import { getHeaders } from '@utils/common-methods';
const tokenCache = {};
export const getToken = async () => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  if (tokenCache.tokenInfo && moment().isBefore(tokenCache.tokenInfo.expires_at)) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return tokenCache.tokenInfo;
  }
  try {
    const result = await axiosGetUnstable(
      `${process.env.CROPWISE_REMOTE_SENSING_URL}/session/signature`,
      {
        headers: getHeaders(),
        timeout: 1000 * 60 * 15
      }
    );
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    tokenCache.tokenInfo = result.data;
    return result.data;
  } catch (err) {
    return { session_token: '' };
  }
};

import React, { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { Select } from 'antd';
import { isMultifieldRecommendationLoad } from '@selectors/headerSelectors';
import { CustomSelect } from '@CustomSelect/CustomSelect';
import { getOrgCountryConfig } from '@selectors/orgConfigSelectors';
import { calculateDefaultSeason } from '@reducer/Seasons/initialSeasonsState';
import { setSelectedSeason } from '@reducer/Seasons/reducer';
import { updateReseller } from '@reducer/Manager';
import { availableSeasonsOrWithData, selectedSeason } from '@selectors/Seasons/selectedSeason';
import { getSpanSeasons } from '@selectors/Seasons/spanningSeasons';
import { DropdownDefaultPlacholder, DropdownDefaultTitle, DropdownDefaultTitleDiv } from './styles';
import { CustomDropdown } from './commonComponents';

const { Option } = Select;

type SeasonSelectProps = {
  isAllFieldLoaded: boolean;
  selectedOrg: any;
};

export const SeasonSelect = (props: SeasonSelectProps) => {
  const { isAllFieldLoaded, selectedOrg } = props;
  const dispatch = useDispatch();
  const history = useLocation();
  const { t } = useTranslation();
  const countryConfig = useSelector(getOrgCountryConfig);
  const seasonList = useSelector(availableSeasonsOrWithData);
  const countrySpanSeasons = useSelector(getSpanSeasons);
  const selectedYear = useSelector(selectedSeason);
  const isRecommendationLoad = useSelector(isMultifieldRecommendationLoad);
  const displayYearSeason = countrySpanSeasons
    ? `${Number(selectedYear) - 1}/${selectedYear}`
    : selectedYear;

  const getSeasons = (seasons: string[]) => {
    const seasonsFormatted =
      countrySpanSeasons !== undefined
        ? seasons.map((season) => ({
            keyId: season,
            display: `${Number(season) - 1}/${season}`
          }))
        : seasons.map((season) => ({
            keyId: season,
            display: season
          }));

    return seasonsFormatted.map((season) => (
      <Option
        data-testid={`header-seasonDropdown-season-item-${season.keyId}`}
        key={season.keyId}
        value={season.keyId}
        name={season.display}
        className='seasonOption'
      >
        <DropdownDefaultTitleDiv style={{ padding: '0 0 0 10px' }}>
          <DropdownDefaultTitle className='seasontitle'>{t('Season')} </DropdownDefaultTitle>
          <DropdownDefaultPlacholder style={{ width: '190px' }} title={String(season.display)}>
            {season.display}
          </DropdownDefaultPlacholder>
        </DropdownDefaultTitleDiv>
      </Option>
    ));
  };
  const filterByYear = useCallback(
    (season: string) => {
      const currentYear = Number(calculateDefaultSeason(countryConfig?.StartOfSellingSeason));
      return Number(season) >= currentYear;
    },
    [countryConfig?.StartOfSellingSeason]
  );

  const onSeasonChange = useCallback(
    (val) => {
      dispatch(setSelectedSeason(val));
      dispatch(updateReseller());
    },
    [dispatch]
  );

  useEffect(() => {
    if (history.pathname.includes('/app/crop')) {
      const currentYear = Number(calculateDefaultSeason(countryConfig?.StartOfSellingSeason));

      if (Number(selectedYear) < currentYear) {
        dispatch(setSelectedSeason(String(currentYear)));
        dispatch(updateReseller());
      }
    }
  }, [countryConfig, dispatch, history, selectedOrg, selectedYear]);

  return (
    <CustomSelect
      data-testid='collapsedHeader-select-season'
      disabled={
        (history.pathname.includes('/app/crop') && history.search.includes('solution_id')) ||
        isRecommendationLoad ||
        isAllFieldLoaded
      }
      dropdownMatchSelectWidth={true}
      size='large'
      className='seasonSelect'
      onSelect={onSeasonChange}
      bordered={false}
      margintop={'0'}
      value={
        selectedYear ? (
          <Option
            key={displayYearSeason}
            value={displayYearSeason}
            name={displayYearSeason}
            className='seasonOption'
          >
            <DropdownDefaultTitleDiv style={{ padding: '0 0 0 10px' }}>
              <DropdownDefaultTitle>{t('Season')}</DropdownDefaultTitle>
              <DropdownDefaultPlacholder>{displayYearSeason}</DropdownDefaultPlacholder>
            </DropdownDefaultTitleDiv>
          </Option>
        ) : (
          'default'
        )
      }
      dropdownStyle={{ borderRadius: 8, left: 90, top: 60 }}
      style={{
        width: 160,
        margin: '0 0.5em',
        verticalAlign: 'middle',
        padding: '10px 0'
      }}
      dropdownRender={(menu) => <CustomDropdown menu={menu} t={t} label={'Seasons'} />}
    >
      {!selectedYear && (
        <Option hidden key='default' value='default'>
          <DropdownDefaultTitleDiv className='dropdownDefaultTitleDiv'>
            <DropdownDefaultTitle>{t('Season')}</DropdownDefaultTitle>
            <DropdownDefaultPlacholder>{t('Please Select Season')}</DropdownDefaultPlacholder>
          </DropdownDefaultTitleDiv>
        </Option>
      )}
      {/* spot check, multi-field, and management zones have seasons options of only current crop year and future */}
      {!history.pathname.includes('/app/crop') &&
      !history.pathname.includes('/app/spot-check') &&
      !history.pathname.includes('/app/managementzones')
        ? getSeasons(seasonList)
        : getSeasons(seasonList.filter((season) => filterByYear(season)))}
    </CustomSelect>
  );
};

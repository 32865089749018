import styled from 'styled-components';

export const SoilLayerStyle = styled.div`
  margin: 8px 20px 0 0;
  display: inline-block;
  font-size: 12px;
  line-height: 10px;
  .colorBall {
    width: 10px;
    height: 10px;
    /* border: 1px solid; */
    border-radius: 100%;
    margin: 0 10px 0 0;
  }
`;

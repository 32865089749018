import React from 'react';
import PropTypes from 'prop-types';
import { DeckOfApps } from '@cw-elements/deck-of-apps';
import styled from 'styled-components';

const DeckOfAppsStyle = styled.div`
  margin: 10px 0px;
  z-index: 120000;
`;
const DeckOfAppsStyled = styled(DeckOfApps)`
  z-index: 120000;
`;
export const DeckApp = ({ selectedOrg, userProfile }) => {
  return (
    <DeckOfAppsStyle>
      <DeckOfAppsStyled
        loadingDataFromApp={false}
        workspaceId={
          selectedOrg?.crn?.split('workspace:')[1]?.split('/org')[0] ??
          userProfile?.default_licensing_account_id
        }
        orgId={selectedOrg?.id}
        canChangeHeaderZIndex={false}
      />
    </DeckOfAppsStyle>
  );
};

DeckApp.propTypes = {
  selectedOrg: PropTypes.any,
  userProfile: PropTypes.object
};

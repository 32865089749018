import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Menu, notification, Tooltip } from 'antd';
import {
  StyledSubMenu,
  StyledMenuItem,
  StyledDivider,
  AdditionalServiceWidgetStyle
} from '../styles';
import { ChervonLeftIcon, ExpandLessIcon } from '../../../common/Icons';

export const MapAdditionalServiceWidget = ({
  logAmplitudeEvent,
  topPosition,
  props,
  propertyCount,
  propertyFieldsCount,
  propertyId,
  setMapState,
  mapInstance
}) => {
  let ua = navigator.userAgent;
  let checker = {
    iphone: ua.match(/(iPhone|iPod|iPad)/),
    android: ua.match(/Android/)
  };
  const [isOpened, setIsOpened] = useState(false);
  const activeService = {};
  const { t } = useTranslation();
  return (
    <AdditionalServiceWidgetStyle topPosition={topPosition} {...props}>
      <Menu
        className='map-wheather-menu'
        mode='inline'
        style={{ height: '100%' }}
        onOpenChange={(e) => {
          if (e.length === 1) {
            setIsOpened(true);
          } else {
            setIsOpened(false);
          }
        }}
      >
        <StyledSubMenu
          className='wheather-submenu'
          key='AdditionalServices'
          isiphone={checker.iphone}
          title={
            <Tooltip title={t('Additional Services')}>
              <span>{t('Additional Services')}</span>
              {!isOpened ? (
                <ChervonLeftIcon style={{ float: 'right', marginTop: '10px' }} />
              ) : (
                <ExpandLessIcon style={{ float: 'right', marginTop: '10px' }} />
              )}
            </Tooltip>
          }
        >
          <StyledMenuItem
            key='1'
            style={{ marginBottom: '10px' }}
            onClick={() => {
              //close popup
              try {
                mapInstance.closePopup();
              } catch (e) {
                console.warn(e);
              }
              if (propertyId) {
                if (propertyCount === 1) {
                  if (logAmplitudeEvent) {
                    logAmplitudeEvent('Imagery layer view');
                  }
                  setMapState((draft) => {
                    draft.isImageryOpen = true;
                    draft.isAdditionalServicesOpen = false;
                  });
                } else {
                  notification.error({
                    message: `${t('Please select only one property from list')}`,
                    placement: 'topRight'
                  });
                  return false;
                }
              } else if (propertyFieldsCount === 0) {
                notification.error({
                  message: `${t('Please select at least one field from list')}`,
                  placement: 'topRight'
                });
              } else {
                notification.error({
                  message: `${t('Please select at lease one property from list')}`,
                  placement: 'topRight'
                });
                return false;
              }
            }}
          >
            <Tooltip
              title={
                window.location.host.indexOf('uk') > -1
                  ? t('Seed Selector Imagery')
                  : t('Cropwise Imagery')
              }
            >
              {window.location.host.indexOf('uk') > -1
                ? t('Seed Selector Imagery')
                : t('Cropwise Imagery')}
            </Tooltip>
          </StyledMenuItem>
          <StyledDivider />

          <StyledMenuItem
            style={{ color: activeService['Soil Layer'] ? 'green' : '' }}
            key='2'
            onClick={() => {
              try {
                mapInstance.closePopup();
              } catch (e) {
                console.warn(e);
              }
              if (propertyFieldsCount > 0) {
                if (logAmplitudeEvent) {
                  logAmplitudeEvent('Soil layer view');
                }
                setMapState((draft) => {
                  draft.isSoilLayerOpen = true;
                  draft.isAdditionalServicesOpen = false;
                });
              } else {
                notification.error({
                  message: `${t('Please select at least one field from list')}`,
                  placement: 'topRight'
                });
                return false;
              }
            }}
          >
            {t('Soil Layer')}
          </StyledMenuItem>
          <StyledDivider />
        </StyledSubMenu>
      </Menu>
    </AdditionalServiceWidgetStyle>
  );
};

MapAdditionalServiceWidget.propTypes = {
  logAmplitudeEvent: PropTypes.func,
  mapInstance: PropTypes.shape({
    closePopup: PropTypes.func
  }),
  propertyCount: PropTypes.number,
  propertyFieldsCount: PropTypes.number,
  propertyId: PropTypes.any,
  props: PropTypes.any,
  setMapState: PropTypes.func,
  topPosition: PropTypes.any
};

/* eslint-disable */
import { Polygon, MultiPolygon, bbox } from '@turf/turf';
import { getHeaders } from '@utils/common-methods';
import { ApiCommunicationError } from '../../features/ManagementZones/ApiCommunicationError';
import { FilterImageResponse, ImageHistogram } from './types';
import { axiosGetUnstable } from '@utils/unstableRequest';

// Available expressions that we are passing to fetch image preview data
export const ndviExpr1 = `(B4-B3)/(B4+B3)`;

// Expressions for available sub products
const exprs: Record<string, string> = {
  ndvi: ndviExpr1
};

// Cache some of the data we generate here
const histogramCache: { [key: string]: ImageHistogram | null } = {};


/**
 * @param dateRange uses the DateRange type exported from the same module
 */
export async function getImageAssetCatalogForProperty(
  propertyId: string,
  dateRange: any,
  isField: any
) {
  try {
    let url = '';
    if (dateRange?.to && !isField) {
      url = `${process.env.CROPWISE_REMOTE_SENSING_URL}/aois/images?uid=${propertyId}&start_date=${dateRange.from}&end_date=${dateRange.to}&aoi_type=field&size=150`;
    }
    if (isField) {
      const fieldIds = dateRange;
      const allData = [];
      const hash = {};
      await Promise.all(
        fieldIds.map(async (fieldId, index) => {
          const year = new Date().getFullYear();
          const listOfYears = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
          let imagerResponse = [];
          return await Promise.all(
            listOfYears.map(async (item, childIndex) => {
              const startDate =
                childIndex === 0 ? `${year}-01-01` : `${year - (childIndex + 1)}-01-01`;
              const endDate =
                childIndex === 0 ? `${year}-12-31` : `${year - (childIndex + 1)}-12-31`;
              hash[startDate] = {};
              const yeard = [];
              url = `${process.env.CROPWISE_REMOTE_SENSING_URL}/aois/images?uid=${fieldId}&start_date=${startDate}&end_date=${endDate}&aoi_type=field&size=150`;
              return new Promise((resolve) => {
                const getImageResponse = async (imageUrlAPI = url) => {
                  imagerResponse = await axiosGetUnstable<FilterImageResponse[]>(imageUrlAPI, {
                    headers: getHeaders().common,
                    timeout: 1000 * 60 * 15
                  });
                  allData.push(imagerResponse.data.content);
                  yeard.push(imagerResponse.data.content);
                  if (imagerResponse?.data?.cursor) {
                    if (imageUrlAPI.includes('cursor')) {
                      const newImageAPI = imageUrlAPI.split('&cursor=')[0];
                      await getImageResponse(`${newImageAPI}&cursor=${imagerResponse.data.cursor}`);
                    } else {
                      await getImageResponse(`${imageUrlAPI}&cursor=${imagerResponse.data.cursor}`);
                    }
                  } else {
                    hash[startDate] = yeard;
                    resolve(allData);
                  }
                };
                getImageResponse(url);
              });
            })
          );
        })
      );
      const data = allData
        .flat()
        .filter((item) => item)
        .map((item) => ({
          asset_id: item.id,
          source: item.source,
          acquisition_date: item.acquired_at,
          cloud_cover: item.scene_cloud_coverage
        }));
      return data;
    } else {
      const imageData = [];
      return new Promise((resolve) => {
        const getImageResponse = async (imageUrlAPI = url) => {
          const imagerResponse = await axiosGetUnstable<FilterImageResponse[]>(imageUrlAPI, {
            headers: getHeaders().common,
            timeout: 1000 * 60 * 15
          });
          imageData.push(imagerResponse.data.content);
          if (imagerResponse?.data?.cursor) {
            if (imageUrlAPI.includes('cursor')) {
              const newImageAPI = imageUrlAPI.split('&cursor=')[0];
              await getImageResponse(`${newImageAPI}&cursor=${imagerResponse.data.cursor}`);
            } else {
              await getImageResponse(`${imageUrlAPI}&cursor=${imagerResponse.data.cursor}`);
            }
          } else {
            resolve(imageData);
          }
        };
        getImageResponse(url);
      });
    }
  } catch (err) {
    console.error(err);
    throw new ApiCommunicationError(
      'Management Zone API',
      'Asset Listing',
      err.response?.status ?? -1,
      err.response?.data?.message ?? err.message
    );
  }
}

export async function getHistogramForImage(
  imageId: string,
  fieldId: string,
  geom: Polygon | MultiPolygon,
  isSAVI: boolean
): Promise<ImageHistogram | null> {
  try {
    if (histogramCache[`${imageId}-${fieldId}`]) {
      return histogramCache[`${imageId}-${fieldId}`];
    }
    //   const maskId = await maskIdForGeometry(fieldId, geom);
    const result = await axiosGetUnstable<ImageHistogram>(
      `${process.env.CROPWISE_REMOTE_SENSING_URL}/images/${imageId}/${
        isSAVI ? 'savi' : 'ndvi'
      }/histogram`,
      {
        headers: getHeaders(),
        timeout: 1000 * 60 * 15
      }
    );

    let histogram: ImageHistogram | null = result.data;
    if (typeof histogram === 'string') {
      histogram = null;
    }
    histogramCache[`${imageId}-${fieldId}`] = histogram;
    return histogram;
  } catch (err) {
    console.error(err);
    throw new ApiCommunicationError(
      'Management Zone API',
      'Asset Listing',
      err.response?.status ?? -1,
      err.response?.data?.message ?? err.message
    );
  }
}


export function buildNdviThumbnailForGeometry(
  imageId: string,
  token: string,
  geom: Polygon | MultiPolygon
) {
  const bounds = bbox(geom);
  const subProduct = 'ndvi';
  const url =
    `${process.env.CROPWISE_REMOTE_SENSING_URL}/images/${imageId}/ndvi/falsecolor/png?` +
    `raster_size=800&` +
    `lower_right_lat=${bounds[1]}&lower_right_lon=${bounds[2]}&` +
    `upper_left_lat=${bounds[3]}&upper_left_lon=${
      bounds[0]
    }&session_token=${token}&dynamic_band_expression=${encodeURIComponent(
      JSON.stringify(btoa(exprs[`${subProduct}`]))
    )}`;
  return url;
}

export function buildPngThumbnailForGeometry(
  imageId: string,
  token: string,
  geom: Polygon | MultiPolygon
) {
  // This is pointed to the old farmshots API as the current imagery API does not work with satellite images ATM
  // const maskId = await maskIdForGeometry(fieldId, geom);
  // const bounds = bbox(geom);
  const bounds = bbox(geom);
  const url =
    `${process.env.CROPWISE_REMOTE_SENSING_URL}/images/${imageId}/rgb/truecolor/png?` +
    `raster_size=800&` +
    `lower_right_lat=${bounds[1]}&lower_right_lon=${bounds[2]}&` +
    `upper_left_lat=${bounds[3]}&upper_left_lon=${bounds[0]}&session_token=${token}`;
  return url;
}

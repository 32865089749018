/* tslint:disable */
/* eslint-disable */
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { bbox } from '@turf/turf';
import isEmpty from 'lodash/isEmpty';
import { Checkbox, ConfigProvider, DatePicker, Empty, Radio, Slider, Spin, Tooltip } from 'antd';
import moment from 'moment';
import produce from 'immer';
import { useSelector } from 'react-redux';
import { currentFeatureSet, isEnabled } from '@selectors/featureSet';
import { convertFromHectareToUOMUnit, convertUnitTextForUOM } from '@utils/getUOMConversions';
import { getLocale } from '@utils/getDatePickerLocale';
import {
  buildNdviThumbnailForGeometry,
  buildPngThumbnailForGeometry,
  getHistogramForImage,
  getImageAssetCatalogForProperty
} from '../../../services/imageryService/cropwiseImagery';
import {
  ChervonLeftIcon,
  ChervonRightIcon,
  CloseIcon,
  SettingsIcon,
  SvgLoader,
  SvgRadioOn
} from '../../../common/Icons';

import ukLogoImg from '../../../../public/SeedSelectorIcon.svg';
import CropwiseImageryLogo from '../../../../public/rgb-cropwise-imagery-RHS-text.svg';

import { Histogram } from './ImageryHistogram';
import { getToken } from '../../../services/imageryService/dispatchImagery';

function padTo2Digits(num) {
  return num.toString().padStart(2, '0');
}

function formatDate(date) {
  return [date.getFullYear(), padTo2Digits(date.getMonth() + 1), padTo2Digits(date.getDate())].join(
    '-'
  );
}
let imageUnderlayLayer = [];
export const ImageryWidget = (props) => {
  const featureSet = useSelector(currentFeatureSet);
  // eslint-disable-next-line no-empty-function
  const {
    closeImageryWidget,
    mapInstance,
    // eslint-disable-next-line no-empty-function
    updateIsChecked = () => {},
    propertyFields,
    topPosition
  } = props;
  const [fields, setFields] = useState([]);

  function cleanupMapLayers(mapInstance) {
    if (!isEmpty(mapInstance)) {
      for (let layer of imageUnderlayLayer) {
        if (layer instanceof L.TileLayer) {
          mapInstance.removeLayer(layer); // Remove each tile layer
        }
      }
      // Clear the imageUnderlayLayer array
      imageUnderlayLayer.length = 0;
    }
  }
  useEffect(() => {
    return function cleanup() {
      cleanupMapLayers(mapInstance);
    };
  }, []);

  useEffect(() => {
    setFields(propertyFields);
  }, [propertyFields]);
  const { t } = useTranslation();
  const { RangePicker } = DatePicker;
  const [state, setState] = useState({
    activeTab: 'default',
    isLoading: true
  });
  const [filters, setFilters] = useState({
    cloudCover: 5,
    checkedAssetsOpacities: {},
    sources: ['sentinel', 'landsat8'],
    algorithm: 'NDVIR'
  });
  const [imagery, setImagery] = useState({});
  const [originalImagery, setOriginalImagery] = useState({});
  const [assetStatus, setAssetStatus] = useState({});
  const [histogramInfo, setHistogramInfo] = useState({});

  const handleCloudCoverChange = (value) => {
    if (setFilters) {
      setFilters({
        ...filters,
        cloudCover: value
      });
    }
  };
  const Close = () => (
    <CloseIcon
      fill='#c1c5c8'
      cursor='pointer'
      onClick={() => {
        cleanupMapLayers(mapInstance);
        closeImageryWidget(false);
      }}
      style={{ marginLeft: 0 }}
    />
  );
  /***
   *
   *
   *  {
   *         from: moment(range[0]).format('DD/MM/YYYY'),
   *         to: moment(range[1]).format('DD/MM/YYYY')
   *       }
   */
  const { activeTab, isLoading } = state;
  const handleTimeRangeChange = (range) => {
    setFilters({
      ...filters,
      isDateChanged: true,
      timeRange: {
        from: range[0],
        to: range[1]
      }
    });
  };

  const restrictDateFromFuture = (current) => {
    return !!current && current > moment().endOf('day');
  };
  const handleAlgorithmChange = (e) => {
    if (setFilters) {
      setFilters({
        ...filters,
        algorithm: e.target.value
      });
    }
  };

  const handleSourceChange = (checkedSources) => {
    if (setFilters) {
      setFilters({
        ...filters,
        sources: checkedSources.map((c) => c.toString())
      });
    }
  };
  const sourcesOptions = [
    { label: 'Sentinel', value: 'sentinel' },
    { label: 'Landsat8', value: 'landsat8' }
  ];
  const applyFilters = () => {
    if (filters.isDateChanged) {
      cleanupMapLayers(mapInstance);
      // empty state
      // Call APi and update State
      loadImagery({
        from: moment(filters.timeRange.from).format('YYYY-MM-DD'),
        to: moment(filters.timeRange.to).format('YYYY-MM-DD')
      });
      setState((prevState) => ({ ...prevState, isLoading: true }));
    } else {
      const { sources } = filters;
      const updatedImagery = {};
      Object.keys(originalImagery).forEach((assets) => {
        const list = [];
        originalImagery[assets].forEach((items) => {
          if (sources.includes(items.source)) {
            list.push(items);
          }
        });
        if (list.length > 0) {
          updatedImagery[assets] = list;
        }
      });
      setImagery(updatedImagery);
    }
  };
  const loadImagery = async (dateRange) => {
    if (fields.length === 0) {
      return;
    }
    try {
      setImagery({});
      setOriginalImagery({});
      const responseImages = await Promise.all(
        fields.map(async (fitem) => await getImageAssetCatalogForProperty(fitem.id, dateRange))
      );
      const assetIds = {};
      responseImages
        .flat()
        .flat()
        .forEach((item) => {
          assetIds[item.id] = item;
        });
      const uniqueKeys = {};
      Object.keys(assetIds).forEach((item) => {
        const dateFormat = formatDate(new Date(`${assetIds[item].acquired_at}`));
        if (uniqueKeys[dateFormat]) {
          uniqueKeys[dateFormat].push({
            key: item,
            ...assetIds[item],
            date: dateFormat,
            asset_id: assetIds[item].id,
            cloud_cover: assetIds[item].image_cloud_coverage
          });
        } else {
          uniqueKeys[dateFormat] = [
            {
              key: item,
              ...assetIds[item],
              date: dateFormat,
              asset_id: assetIds[item].id,
              cloud_cover: assetIds[item].image_cloud_coverage
            }
          ];
        }
      });
      setImagery(uniqueKeys);
      setOriginalImagery(uniqueKeys);
      setState((preState) => ({ ...preState, isLoading: false, error: '' }));
    } catch (e) {
      setState((preState) => ({ ...preState, isLoading: false, error: 'No data' }));
    }
  };
  useEffect(() => {
    const d = new Date();
    if (fields && fields.length === 0) {
      return;
    }
    const startDate = formatDate(d);
    let endDate = d.setMonth(d.getMonth() - 1);

    setFilters({
      ...filters,
      isDateChanged: false,
      timeRange: {
        from: moment(new Date(endDate)),
        to: moment(new Date())
      }
    });
    endDate = formatDate(new Date(endDate));
    loadImagery({ to: startDate, from: endDate });
  }, [JSON.stringify(fields)]);
  const formatSliderTooltip = (value) => `${value}%`;
  const getColor = (cloudCover) => {
    if (cloudCover >= 0.5) {
      return '#f42727';
    } else {
      if (cloudCover >= 0.05 && cloudCover < 0.5) {
        return '#eecd17';
      } else {
        return '#1fca53';
      }
    }
  };
  const getCloudCoverColor = (assets) => {
    let sumCloudCover = 0;
    assets.forEach((asset) => {
      sumCloudCover += asset.cloud_cover;
    });
    const cloudCover = sumCloudCover / assets.length;
    return {
      color: getColor(cloudCover),
      cloudCover: cloudCover * 100
    };
  };
  const handleAssetSelect = (e, asset) => {
    if (e.target.checked) {
      setFilters((preState) => ({
        ...preState,
        checkedAssetsOpacities: {
          ...filters.checkedAssetsOpacities,
          [asset.asset_id]: {
            ...asset,
            opacity: 100,
            checked: true
          }
        }
      }));
      setAssetStatus((prevState) => ({ ...prevState, [asset.asset_id]: true }));
    } else if (filters.checkedAssetsOpacities[asset.asset_id]) {
      const removedAssets = filters.checkedAssetsOpacities;
      delete removedAssets[asset.asset_id];
      setFilters((preState) => ({ ...preState, checkedAssetsOpacities: { ...removedAssets } }));
      setAssetStatus((prevState) => ({ ...prevState, [asset.asset_id]: false }));
    }
  };
  const handleAssetSelectAll = (e, assets) => {
    setHistogramInfo({});
    if (e.target.checked) {
      const updatedAssets = {};
      const hashAsset = {};
      assets.forEach((items) => {
        hashAsset[items.asset_id] = true;
        updatedAssets[items.asset_id] = {
          ...items,
          opacity: 100,
          checked: true
        };
      });
      setAssetStatus((prevState) => ({ ...prevState, ...hashAsset }));
      setFilters((preState) => ({
        ...preState,
        checkedAssetsOpacities: {
          ...filters.checkedAssetsOpacities,
          ...updatedAssets
        }
      }));
    } else {
      const ids = assets.map((items) => items.asset_id);
      const hashAsset = {};
      ids.forEach((itemId) => {
        hashAsset[itemId] = false;
      });
      const updatedAssets = {};
      Object.keys(filters.checkedAssetsOpacities).forEach((assetKey) => {
        if (!ids.includes(assetKey)) {
          updatedAssets[assetKey] = filters.checkedAssetsOpacities[assetKey];
        }
      });
      setAssetStatus((prevState) => ({ ...prevState, ...hashAsset }));
      setFilters((preState) => ({
        ...preState,
        checkedAssetsOpacities: {
          ...updatedAssets
        }
      }));
    }
  };
  const handleOpacityChange = (value, asset, allAssets) => {
    if (allAssets) {
      const updatedAssets = {};
      allAssets.forEach((items) => {
        if (filters.checkedAssetsOpacities[items.asset_id]) {
          updatedAssets[items.asset_id] = {
            ...items,
            ...filters.checkedAssetsOpacities[items.asset_id],
            opacity: value
          };
        }
      });
      const selected = filters.checkedAssetsOpacities[asset.asset_id]
        ? filters.checkedAssetsOpacities[asset.asset_id]
        : {};
      setFilters((preState) => ({
        ...preState,
        checkedAssetsOpacities: {
          ...filters.checkedAssetsOpacities,
          [asset.asset_id]: {
            ...selected,
            opacity: value
          },
          ...updatedAssets
        }
      }));
    } else if (filters.checkedAssetsOpacities[asset.asset_id]) {
      setFilters((preState) => ({
        ...preState,
        checkedAssetsOpacities: {
          ...filters.checkedAssetsOpacities,
          [asset.asset_id]: {
            ...filters.checkedAssetsOpacities[asset.asset_id],
            opacity: value
          }
        }
      }));
    }
  };
  const assetCloudTooltip = (asset) =>
    `${t('general_map_multilayer_farmshots_cloudWithCount', {
      count: Math.round(asset.cloud_cover * 100)
    })}%`;

  const styles = `
   .ant-calendar-range-middle {
    color: #1ba644;
    left: 68%;
  }
  .ant-calendar-range-right .ant-calendar-date-input-wrap{
  margin-left: 0;
  }
  .ant-calendar-range .ant-calendar-input {
    text-align: center;
    color: #009933;
  }
  .ant-calendar-range-picker-separator {
   color: #009933 !important;
  }
  `;
  const Settings = () => {
    return (
      <div style={{ display: 'flex', flexDirection: 'column', width: '280px' }}>
        <div style={{ display: 'flex' }}>
          <span
            style={{ width: '20%', padding: '10px', cursor: 'pointer' }}
            data-testid='imagery-settings'
          >
            <ChervonRightIcon
              onClick={() => {
                setState((prevState) => ({ ...prevState, activeTab: 'default' }));
                applyFilters();
              }}
            />
          </span>

          <span style={{ width: '150px', padding: '14px' }}>
            {t('general_map_multilayer_form_farmshots_settings')}
          </span>
          <span
            style={{ paddingTop: '5px', paddingLeft: '40px', cursor: 'pointer' }}
            data-testid='imagery-close'
          >
            <Close />
          </span>
        </div>
        <div style={{ padding: '10px' }}>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <div>
              <label style={{ lineHeight: '1px' }}>
                {t('general_map_multilayer_form_farmshots_algorithm')}
              </label>{' '}
              <br />
              <Radio.Group onChange={handleAlgorithmChange} value={filters.algorithm}>
                <Radio value={'NDVIR'}>NDVIR</Radio>
                <Radio value={'SAVI'}>SAVI</Radio>
                <Radio value={'RGB'}>RGB</Radio>
                {/*<Radio value={'Variability'}>Variability</Radio>*/}
              </Radio.Group>
              <br />
              <label style={{ lineHeight: '1px' }}>
                {t('general_map_multilayer_form_farmshots_sources')}
              </label>{' '}
              <br />
              <Checkbox.Group
                options={sourcesOptions}
                value={filters.sources}
                onChange={handleSourceChange}
              />
              <br />
              <label style={{ lineHeight: '1px' }}>
                {t('general_map_multilayer_form_farmshots_time_range')}
              </label>{' '}
              <br />
              <style>{styles}</style>
              <ConfigProvider locale={getLocale()}>
                <RangePicker
                  onChange={handleTimeRangeChange}
                  allowClear={false}
                  disabledDate={restrictDateFromFuture}
                  separator='-'
                  format='DD MMM, YY'
                  defaultValue={[moment(filters.timeRange.from), moment(filters.timeRange.to)]}
                />
              </ConfigProvider>
            </div>
          </div>
          <br />
        </div>
      </div>
    );
  };

  const ListOfScenes = () => {
    return (
      <div style={{ display: 'flex', flexDirection: 'column', width: '280px' }}>
        <div style={{ display: 'flex' }}>
          <span
            style={{ width: '20%', padding: '10px', cursor: 'pointer' }}
            data-testid='imagery-settings'
          >
            <ChervonRightIcon
              onClick={() => {
                setState((prevState) => ({ ...prevState, activeTab: 'default' }));
              }}
            />
          </span>

          <span style={{ width: '150px', padding: '10px' }}>
            {state.assets[0].date} <br />
            <span style={{ padding: '10px' }}> {state.assets[0].source} </span>
          </span>
          <span style={{ paddingTop: '5px', paddingLeft: '40px' }}>
            <Close />
          </span>
        </div>
        <div
          style={{ display: 'flex', overflow: 'auto', maxHeight: '11rem', flexDirection: 'column' }}
        >
          {state.assets.map((asset, index) => {
            const { color } = getCloudCoverColor([asset]);

            return (
              <div
                style={{
                  display: 'flex',
                  paddingBottom: '10px',
                  flexWrap: 'wrap',
                  borderTop: '1px solid #e8eaed',
                  padding: '12px 5px 12px 25px'
                }}
                key={asset.asset_id}
              >
                <div style={{ display: 'flex', maxHeight: '10px' }}>
                  <Checkbox
                    checked={
                      filters.checkedAssetsOpacities[asset.asset_id] &&
                      filters.checkedAssetsOpacities[asset.asset_id].checked
                    }
                    onChange={(e) => handleAssetSelect(e, asset)}
                  />
                </div>
                <div>
                  <div style={{ paddingLeft: '15px' }}>
                    <span>{asset.date}</span>
                    <Tooltip title={assetCloudTooltip(asset)}>
                      <SvgRadioOn
                        fill={color}
                        style={{ margin: '0px 8px', width: '12px', height: '12px' }}
                      />
                    </Tooltip>
                  </div>
                  {filters.checkedAssetsOpacities[asset.asset_id] &&
                    filters.checkedAssetsOpacities[asset.asset_id].checked && (
                      <>
                        <label style={{ marginLeft: '-15px' }}>
                          {t('general_map_multilayer_form_farmshots_inconsistent_opacity')}
                        </label>
                        <br />

                        <div style={{ display: 'flex', marginLeft: '-30px' }}>
                          <Slider
                            style={{ width: '135px', marginLeft: '20px' }}
                            defaultValue={filters.checkedAssetsOpacities[asset.asset_id].opacity}
                            tipFormatter={formatSliderTooltip}
                            onChange={(value) => handleOpacityChange(value, asset)}
                          />
                        </div>
                      </>
                    )}
                </div>
              </div>
            );
          })}
        </div>
      </div>
    );
  };

  useEffect(() => {
    cleanupMapLayers(mapInstance);
    if (Object.keys(filters.checkedAssetsOpacities).length > 0) {
      updateIsChecked(true);
      const imageUrls = {};
      let overAllHistogram = {};
      Object.keys(filters.checkedAssetsOpacities).forEach(async (itemAsset, indexI) => {
        const assetId = filters.checkedAssetsOpacities[itemAsset].key;
        try {
          const sessionToken = await getToken();
          const imagesList = [];
          fields.forEach(async (field) => {
            if (filters.algorithm !== 'Variability') {
              const rgb = await buildPngThumbnailForGeometry(
                assetId,
                sessionToken.session_token,
                field.geometry
              );
              const ndvi = await buildNdviThumbnailForGeometry(
                assetId,
                sessionToken.session_token,
                field.geometry
              );
              const bounds = bbox(field.geometry); // minX minY maxX maxY
              const date_entry = {
                image: {
                  lat_long: {
                    lr_lat: bounds[1],
                    lr_lon: bounds[2],
                    ul_lat: bounds[3],
                    ul_lon: bounds[0]
                  }
                }
              };
              imageUrls[itemAsset] = {
                ...filters.checkedAssetsOpacities[itemAsset],
                ok: 'pl',
                lat_long: date_entry.image.lat_long,
                ndvi_url: ndvi,
                png_url: rgb,
                histogram: undefined,
                variability_view_url: undefined,
                bounds: [
                  [date_entry.image.lat_long.lr_lat, date_entry.image.lat_long.ul_lon],
                  [date_entry.image.lat_long.ul_lat, date_entry.image.lat_long.lr_lon]
                ]
              };
              const imageConfig = imageUrls[itemAsset];
              let url = imageConfig.ndvi_url;
              let subproduct = 'ndvi';
              let raster = 'falsecolor';
              if (filters.algorithm === 'RGB') {
                url = imageConfig.png_url;
              }
              const tile = L.tileLayer(
                `${process.env.CROPWISE_REMOTE_SENSING_URL}/images/${assetId}/${subproduct}/${raster}/tiles/{z}/{x}/{y}/png?session_token=${sessionToken.session_token}`,
                {
                  zIndex: 100,
                  opacity: imageConfig.opacity / 100
                }
              ).addTo(mapInstance);
              imageUnderlayLayer.push(tile);
              imagesList[field.id] = url;
            }
            setAssetStatus((prevState) => ({ ...prevState, [itemAsset]: false, imagesList }));
          });
          if (indexI === Object.keys(filters.checkedAssetsOpacities).length - 1) {
            try {
              let histogram;
              for (const field of fields) {
                overAllHistogram = await getHistogramForImage(
                  assetId,
                  field.id,
                  field.geometry,
                  filters.algorithm === 'SAVI'
                );
                try {
                  if (histogram) {
                    histogram = {
                      bins: overAllHistogram.bins.map(
                        // eslint-disable-next-line no-loop-func
                        (bItem, index) => histogram.bins[index] + bItem.count
                      ),
                      max: overAllHistogram.max,
                      mean: overAllHistogram.mean + histogram.mean,
                      min: overAllHistogram.min,
                      std_dev: overAllHistogram.std_dev + histogram.std_dev
                    };
                  } else {
                    histogram = {
                      bins: overAllHistogram.bins.map((itemh) => itemh.count),
                      max: overAllHistogram.max,
                      mean: overAllHistogram.mean,
                      min: overAllHistogram.min,
                      std_dev: overAllHistogram.std_dev
                    };
                  }
                } catch (e) {
                  console.warn(e, 'Line 579 ===>');
                }
              }
              const prepareHistogram = (hist) => {
                let min = hist.min;
                const max = hist.max;
                const step = (max - min) / hist.bins.length;
                const bins = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
                let sumPixels = 0;
                for (const bin of hist.bins) {
                  sumPixels += bin;
                  if (min < 0.1) {
                    bins[0] += bin;
                  }
                  if (min >= 0.1 && min < 0.2) {
                    bins[1] += bin;
                  }
                  if (min >= 0.2 && min < 0.3) {
                    bins[2] += bin;
                  }
                  if (min >= 0.3 && min < 0.4) {
                    bins[3] += bin;
                  }
                  if (min >= 0.4 && min < 0.5) {
                    bins[4] += bin;
                  }
                  if (min >= 0.5 && min < 0.6) {
                    bins[5] += bin;
                  }
                  if (min >= 0.6 && min < 0.7) {
                    bins[6] += bin;
                  }
                  if (min >= 0.7 && min < 0.8) {
                    bins[7] += bin;
                  }
                  if (min >= 0.8 && min < 0.9) {
                    bins[8] += bin;
                  }
                  if (min >= 0.9) {
                    bins[9] += bin;
                  }
                  min += step;
                }
                return {
                  bins,
                  sumPixels
                };
              };
              const binForHistogram = prepareHistogram(histogram);

              const addChart = (bin, sumPixels, source) => {
                const productHistogram = {
                  bin_edges: [
                    '0',
                    '0.1',
                    '0.2',
                    '0.3',
                    '0.4',
                    '0.5',
                    '0.6',
                    '0.7',
                    '0.8',
                    '0.9',
                    '1'
                  ],
                  colors: [
                    '#808080',
                    '#ff0e00',
                    '#ff4500',
                    '#ff7e00',
                    '#ffb800',
                    '#fffe00',
                    '#c6dd00',
                    '#8ebc00',
                    '#549b00',
                    '#0e7000'
                  ],
                  hist: bin,
                  pixel_count: sumPixels,
                  res: source === 'sentinel' ? 10 : 30
                };

                const pxArea = productHistogram.res * productHistogram.res;
                const histArea = productHistogram.hist.map((value) => {
                  return (value * pxArea) / 10000;
                });
                const total = (pxArea * productHistogram.pixel_count) / 10000;
                const histAreaSeries = [];
                const isUOMEnabled = isEnabled('UnitOfMeasurement', featureSet);
                histArea.forEach((_, index) => {
                  histAreaSeries.push({
                    name: isUOMEnabled
                      ? Number(
                          convertFromHectareToUOMUnit({
                            input: histArea[index],
                            featureFlag: true
                          })
                        )
                      : histArea[index],
                    data: isUOMEnabled
                      ? [
                          Number(
                            convertFromHectareToUOMUnit({
                              input: histArea[index],
                              featureFlag: true
                            })
                          )
                        ]
                      : [histArea[index]]
                  });
                });
                const yMax = Math.ceil(
                  histArea.reduce((a, b) => {
                    return Math.max(a, b);
                  })
                );
                let states = {
                  options: {
                    plotOptions: {
                      bar: {
                        dataLabels: {
                          position: 'top' // top, center, bottom
                        }
                      }
                    },
                    dataLabels: {
                      enabled: false,
                      formatter: function (val) {
                        return Number(val);
                      },
                      offsetY: -20,
                      style: {
                        fontSize: '12px',
                        colors: ['#304758']
                      }
                    },
                    xaxis: {
                      categories: productHistogram.bin_edges,
                      position: 'bottom',
                      labels: {
                        offsetY: -5,
                        offsetX: 0,
                        style: {
                          colors: ['#515253'],
                          fontSize: '8px',
                          cssClass: 'apexcharts-xaxis-label'
                        }
                      },
                      axisBorder: {
                        show: true
                      },
                      axisTicks: {
                        show: false
                      },
                      tooltip: {
                        enabled: false,
                        offsetY: -35
                      },
                      formatter: function () {
                        return '';
                      }
                    },
                    yaxis: {
                      max: Number(
                        convertFromHectareToUOMUnit({
                          input: yMax,
                          featureFlag: isUOMEnabled
                        })
                      ),
                      axisBorder: {
                        show: false
                      },
                      axisTicks: {
                        show: false
                      },
                      tickAmount: 2,
                      labels: {
                        show: true,
                        formatter: function (value) {
                          let val = Math.abs(value);
                          return val.toFixed(0);
                        }
                      },
                      title: {
                        text: `${t('Area')} (${convertUnitTextForUOM({
                          unit: 'ha',
                          t: t,
                          longCode: false,
                          featureFlag: isUOMEnabled
                        })})`,
                        offsetX: 0,
                        offsetY: 12,
                        style: {
                          color: undefined,
                          fontSize: '10px'
                        }
                      }
                    },
                    title: {
                      floating: true,
                      offsetY: 0,
                      align: 'center',
                      style: {
                        color: '#444'
                      }
                    },
                    colors: [
                      '#808080',
                      '#ff0e00',
                      '#ff4500',
                      '#ff7e00',
                      '#ffb800',
                      '#fffe00',
                      '#c6dd00',
                      '#8ebc00',
                      '#549b00',
                      '#0e7000'
                    ],
                    chart: {
                      animations: {
                        enabled: false
                      },
                      height: 150,
                      width: 245,
                      fontFamily: 'Roboto',
                      toolbar: {
                        show: false
                      }
                    },
                    legend: {
                      show: false
                    },
                    tooltip: {
                      x: {
                        show: false
                      },
                      y: {
                        title: '',
                        formatter: function (val) {
                          const newTotal = isUOMEnabled
                            ? Number(
                                convertFromHectareToUOMUnit({
                                  input: total,
                                  featureFlag: true
                                })
                              )
                            : total;
                          const percentage = ((val / newTotal) * 100).toFixed(2);
                          return `${
                            t('general_map_multilayer_form_farmshots_area') +
                            parseFloat(val).toFixed(2)
                          } ${convertUnitTextForUOM({
                            unit: 'ha',
                            t: t,
                            longCode: false,
                            featureFlag: isUOMEnabled
                          })}
                          <br/>${percentage}%`;
                        }
                      },
                      z: {
                        formatter: function () {
                          return '';
                        }
                      }
                    },
                    toolbar: {
                      show: false
                    }
                  },
                  series: histAreaSeries
                };
                const addHistoGram = produce(histogramInfo, (draft) => {
                  draft[assetId] = { histogram: states };
                });
                setHistogramInfo(addHistoGram);
              };

              addChart(
                binForHistogram.bins,
                binForHistogram.sumPixels,
                filters.checkedAssetsOpacities[itemAsset].source
              );
            } catch (err) {
              console.warn(`Unable to get the top-level histogram for image ${assetId}`);
              console.warn(err);
            }
          }
        } catch (e) {
          // eslint-disable-next-line no-console
          console.log('Imagery error', e);
          setAssetStatus((prevState) => ({ ...prevState, [itemAsset]: false }));
        }
      });
    } else {
      updateIsChecked(false);
    }
  }, [filters.checkedAssetsOpacities]);

  return (
    <>
      <div
        style={{
          zIndex: 1001,
          position: 'absolute',
          left: '12px',
          top: topPosition || `7px`,
          marginLeft: 'calc(100% - 300px)',
          background: '#fff',
          borderRadius: '8px'
        }}
      >
        <div
          style={{
            display: activeTab !== 'default' ? 'none' : 'flex',
            flexDirection: 'column',
            padding: '10px',
            width: '280px'
          }}
        >
          <div style={{ display: 'flex' }}>
            <span style={{ width: '20%', paddingTop: '20px' }} data-testid='imagery-settings'>
              <Tooltip title={t('general_map_multilayer_form_farmshots_open_settings')}>
                <SettingsIcon
                  onClick={() => setState((preState) => ({ ...preState, activeTab: 'settings' }))}
                />
              </Tooltip>
            </span>

            <span>
              <img
                src={window.location.host.indexOf('uk') > -1 ? ukLogoImg : CropwiseImageryLogo}
                width='150px'
                alt='Farmshots Logo'
              />
            </span>
            <span
              style={{ paddingLeft: '40px', paddingTop: '0px', cursor: 'pointer' }}
              data-testid='imagery-close'
            >
              <Close />
            </span>
          </div>
          {isLoading && (
            <div style={{ height: '120px', display: 'flex', flexDirection: 'column' }}>
              <Spin />
            </div>
          )}
          {!isLoading && Object.keys(imagery).length === 0 && (
            <span
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
                paddingRight: '5px'
              }}
            >
              <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={t('No data')} />
            </span>
          )}
          {!isLoading && Object.keys(imagery).length > 0 && !state.error && (
            <div
              style={{
                display: 'flex',
                flexDirection: 'column'
              }}
            >
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  paddingRight: '5px'
                }}
              >
                <span>{t('general_map_multilayer_form_farmshots_cloud_cover_index')}</span>
                <span> {filters.cloudCover}%</span>
              </div>

              <div>
                <Slider
                  defaultValue={filters.cloudCover}
                  max={100}
                  min={0}
                  onAfterChange={handleCloudCoverChange}
                  tipFormatter={formatSliderTooltip}
                  style={{ marginTop: '15', marginLeft: '5px', marginRight: '5px' }}
                />
              </div>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  overflow: 'auto',
                  maxHeight: '11rem'
                }}
              >
                {Object.keys(imagery)
                  .sort((a, b) => {
                    // eslint-disable-next-line no-param-reassign
                    a = a.split('-').reverse().join('');
                    // eslint-disable-next-line no-param-reassign
                    b = b.split('-').reverse().join('');
                    if (a < b) {
                      return 1;
                    } else if (a > b) {
                      return -1;
                    } else {
                      return 0;
                    }
                  })
                  .map((assets) => {
                    let isAllCheck = true;
                    let atleastOneSelected = false;
                    imagery[assets].forEach((items) => {
                      if (!filters.checkedAssetsOpacities[items.asset_id]) {
                        isAllCheck = false;
                      }
                      if (filters.checkedAssetsOpacities[items.asset_id]) {
                        atleastOneSelected = true;
                      }
                    });
                    return imagery[assets].map((asset, index) => {
                      const { color, cloudCover } = getCloudCoverColor(imagery[assets]);
                      if (index > 0) {
                        return null;
                      }
                      if (filters.cloudCover < cloudCover) {
                        return null;
                      }
                      return (
                        <>
                          <div
                            style={{
                              display: 'flex',
                              paddingBottom: '10px',
                              alignItems: 'center',
                              flexWrap: 'wrap',
                              borderTop: '1px solid #e8eaed',
                              padding: '12px 5px 0px 0px'
                            }}
                            key={asset.asset_id}
                          >
                            {!assetStatus[asset.asset_id] && (
                              <Checkbox
                                indeterminate={isAllCheck ? false : atleastOneSelected}
                                checked={
                                  isAllCheck ||
                                  (filters.checkedAssetsOpacities[asset.asset_id] &&
                                    filters.checkedAssetsOpacities[asset.asset_id].checked)
                                }
                                onChange={(e) => handleAssetSelectAll(e, imagery[assets])}
                              />
                            )}
                            {assetStatus[asset.asset_id] && <SvgLoader />}
                            <div style={{ paddingLeft: '15px' }}>
                              <div>
                                <span>{asset.date}</span>
                                <Tooltip title={assetCloudTooltip(asset)}>
                                  <SvgRadioOn
                                    fill={color}
                                    style={{ margin: '0px 8px', width: '12px', height: '12px' }}
                                  />
                                </Tooltip>
                              </div>
                              <label>
                                {t('general_map_multilayer_form_farmshots_scene', {
                                  count: imagery[assets].length
                                })}
                                • {asset.source}
                              </label>
                            </div>
                            {imagery[assets].length > 1 && (
                              <button
                                data-testid='imagery-left-icon'
                                style={{
                                  width: '20px',
                                  marginLeft: '75px',
                                  cursor: 'pointer',
                                  border: 'none',
                                  background: 'none'
                                }}
                                onClick={() =>
                                  setState((preState) => ({
                                    ...preState,
                                    activeTab: 'listOfScenes',
                                    assets: imagery[assets]
                                  }))
                                }
                              >
                                <ChervonLeftIcon />
                              </button>
                            )}
                          </div>
                          {(filters.checkedAssetsOpacities[asset.asset_id] ||
                            atleastOneSelected) && (
                            <>
                              <label>
                                {t('general_map_multilayer_form_farmshots_change_opacity')}
                              </label>

                              <div style={{ display: 'flex' }}>
                                <Slider
                                  style={{ width: '135px', marginLeft: '6px' }}
                                  defaultValue={
                                    filters.checkedAssetsOpacities[asset.asset_id]
                                      ? filters.checkedAssetsOpacities[asset.asset_id].opacity
                                      : 0
                                  }
                                  tipFormatter={formatSliderTooltip}
                                  onChange={(value) =>
                                    handleOpacityChange(value, asset, imagery[assets])
                                  }
                                />
                              </div>
                            </>
                          )}
                        </>
                      );
                    });
                  })}
              </div>
            </div>
          )}
        </div>

        {activeTab === 'settings' && <Settings />}
        {activeTab === 'listOfScenes' && <ListOfScenes />}
      </div>

      {!isLoading &&
        Object.keys(imagery).length > 0 &&
        !state.error &&
        Object.keys(filters.checkedAssetsOpacities).length > 0 && (
          <Histogram state={histogramInfo} />
        )}
    </>
  );
};

Histogram.propTypes = {
  closeImageryWidget: PropTypes.func,
  mapInstance: PropTypes.any,
  updateIsChecked: PropTypes.func,
  propertyFields: PropTypes.any,
  topPosition: PropTypes.any
};

ImageryWidget.propTypes = {
  closeImageryWidget: PropTypes.func,
  mapInstance: PropTypes.any,
  propertyFields: PropTypes.any,
  topPosition: PropTypes.any,
  updateIsChecked: PropTypes.func
};

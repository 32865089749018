import React, { useEffect, useState } from 'react';
import ReactApexChart from 'react-apexcharts';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { SvgArrowUpGlyph, SvgLoader } from '../../../common/Icons';
import CropwiseImageryLogoIcon from '../../../../public/rgb-cropwise-imagery-icon-only.svg';

const FARMSHOTS_COLORS_256 = [
  '#f85555',
  '#f85655',
  '#f95754',
  '#f95854',
  '#fa5853',
  '#fa5953',
  '#fb5a52',
  '#fb5b52',
  '#fb5c51',
  '#fc5d51',
  '#fc5e50',
  '#fd5f50',
  '#fd5f4f',
  '#fd604f',
  '#fe614f',
  '#fe624e',
  '#fe634e',
  '#ff644d',
  '#ff654d',
  '#ff664c',
  '#ff664c',
  '#ff674b',
  '#ff684b',
  '#ff694a',
  '#ff6a4a',
  '#ff6b49',
  '#ff6c49',
  '#ff6d48',
  '#ff6e48',
  '#ff6f48',
  '#ff6f47',
  '#ff7047',
  '#ff7146',
  '#ff7246',
  '#ff7345',
  '#ff7445',
  '#ff7544',
  '#ff7644',
  '#ff7743',
  '#ff7843',
  '#ff7942',
  '#ff7a42',
  '#ff7a41',
  '#ff7b41',
  '#ff7c41',
  '#ff7d40',
  '#ff7e40',
  '#ff7f3f',
  '#ff803f',
  '#ff813e',
  '#ff823e',
  '#ff833d',
  '#ff843d',
  '#ff853c',
  '#ff863c',
  '#ff863b',
  '#ff873b',
  '#ff883a',
  '#ff893a',
  '#ff8a3a',
  '#ff8b39',
  '#ff8c39',
  '#ff8d38',
  '#ff8e38',
  '#ff8f37',
  '#ff9037',
  '#ff9136',
  '#ff9236',
  '#ff9336',
  '#ff9335',
  '#ff9435',
  '#ff9534',
  '#ff9634',
  '#ff9733',
  '#ff9833',
  '#ff9933',
  '#ff9a32',
  '#ff9b32',
  '#ff9c31',
  '#ff9d31',
  '#ff9e30',
  '#ff9f30',
  '#ffa030',
  '#ffa12f',
  '#ffa22f',
  '#ffa22f',
  '#ffa32e',
  '#ffa42e',
  '#ffa52d',
  '#ffa62d',
  '#ffa72d',
  '#ffa82c',
  '#ffa92c',
  '#ffaa2c',
  '#ffab2b',
  '#ffac2b',
  '#ffad2b',
  '#ffae2b',
  '#ffaf2a',
  '#ffb02a',
  '#ffb02a',
  '#ffb129',
  '#ffb229',
  '#ffb329',
  '#ffb429',
  '#ffb529',
  '#ffb628',
  '#ffb728',
  '#ffb828',
  '#ffb928',
  '#ffba28',
  '#ffbb28',
  '#ffbc28',
  '#ffbd27',
  '#ffbe27',
  '#ffbe27',
  '#ffbf27',
  '#ffc027',
  '#ffc127',
  '#ffc227',
  '#ffc327',
  '#ffc427',
  '#ffc527',
  '#ffc627',
  '#ffc728',
  '#ffc828',
  '#ffc928',
  '#ffca28',
  '#feca28',
  '#fcca27',
  '#fac927',
  '#f8c926',
  '#f6c926',
  '#f4c825',
  '#f2c825',
  '#f0c825',
  '#eec724',
  '#ecc724',
  '#ebc724',
  '#e9c623',
  '#e7c623',
  '#e5c623',
  '#e3c523',
  '#e1c523',
  '#dfc522',
  '#ddc422',
  '#dbc422',
  '#d9c322',
  '#d7c322',
  '#d6c322',
  '#d4c222',
  '#d2c222',
  '#d0c122',
  '#cec122',
  '#ccc122',
  '#cac022',
  '#c8c022',
  '#c7bf22',
  '#c5bf23',
  '#c3bf23',
  '#c1be23',
  '#bfbe23',
  '#bdbd23',
  '#bcbd24',
  '#babc24',
  '#b8bc24',
  '#b6bb24',
  '#b4bb25',
  '#b3ba25',
  '#b1ba25',
  '#afba26',
  '#adb926',
  '#abb926',
  '#aab827',
  '#a8b827',
  '#a6b727',
  '#a4b728',
  '#a3b628',
  '#a1b629',
  '#9fb529',
  '#9db529',
  '#9cb42a',
  '#9ab42a',
  '#98b32b',
  '#96b32b',
  '#95b22b',
  '#93b22c',
  '#91b12c',
  '#90b02d',
  '#8eb02d',
  '#8caf2d',
  '#8aaf2e',
  '#89ae2e',
  '#87ae2f',
  '#85ad2f',
  '#84ad30',
  '#82ac30',
  '#80ab30',
  '#7fab31',
  '#7daa31',
  '#7baa32',
  '#7aa932',
  '#78a933',
  '#76a833',
  '#75a734',
  '#73a734',
  '#71a634',
  '#70a635',
  '#6ea535',
  '#6ca536',
  '#6ba436',
  '#69a336',
  '#68a337',
  '#66a237',
  '#64a238',
  '#63a138',
  '#61a039',
  '#5fa039',
  '#5e9f39',
  '#5c9e3a',
  '#5a9e3a',
  '#599d3b',
  '#579d3b',
  '#569c3b',
  '#549b3c',
  '#529b3c',
  '#519a3d',
  '#4f993d',
  '#4d993d',
  '#4c983e',
  '#4a983e',
  '#48973e',
  '#47963f',
  '#45963f',
  '#43953f',
  '#419440',
  '#409440',
  '#3e9341',
  '#3c9241',
  '#3b9241',
  '#399142',
  '#379042',
  '#359042',
  '#338f42',
  '#318e43',
  '#2f8e43',
  '#2d8d43',
  '#2b8c44',
  '#298c44',
  '#278b44',
  '#258a45',
  '#238a45',
  '#218945',
  '#1e8845',
  '#1c8846',
  '#198746'
];
export const Histogram = (props) => {
  const [isEnabled, setIsEnabled] = useState(true);
  const { t } = useTranslation();
  const [histogramOpen, setHistogramOpen] = useState(false);
  const handleToggleHistogram = () => {
    setHistogramOpen(!histogramOpen);
  };
  /**
   * histoGramInfo
   *
   * [id] : {},
   * [] : {}
   */
  useEffect(() => {
    setIsEnabled(true);
  }, [props]);
  const h =
    Object.keys(props.state).length === 0
      ? { options: {}, series: [] }
      : Object.values(props.state)[0].histogram;
  const styles = `.histogram-collapse-button { border: none; background: none; padding-top: 6px; align-items: center; padding-left: 0; display: flex; cursor: pointer; width: min-content; } .histogram-collapse-button:focus { outline: none; } .histogram-collapse-button.active svg { transform: rotate(180deg); } .histogram-collapse-button svg { transition: transform 0.2s ease-in; } .histogram-container { text-align: center; } 
  .st-map__legend,.st-map__legend--region-selected {position: absolute; display: flex; flex-direction: column; right:10px; width: 270px; background-color: #fff; box-shadow: 0 4px 10px 0 rgba(37,60,83,.24); border-radius: 8px; padding:8px 12px  4px 12px; bottom: 5px; z-index: 1001 } .st-map__legend--region-selected { position: fixed; bottom: 120px } .st-map__legend__groups { display: flex; justify-content: space-between } .st-map__legend__colors { display: flex; margin: 4px 0 } .st-map__legend>.st-typo { display: flex; align-items: center } .st-map__legend>.st-typo>:first-child { margin-right: 4px } .st-map--editable .st-map__legend { right: 56px!important } .st-map__timeline-legend { position: absolute; left: 420px; bottom: 120px; background-color: rgba(0,0,0,.3); width: 222px; min-height: 60px; border-radius: 4px; padding: 12px; display: grid; grid-template-rows: min-content auto } .st-typo--caption-1 { font-size: 12px } .st-typo--caption-2 { font-size: 10px } .apexcharts-tooltip-text-label{display: none;} #histogram-cropwise-imagery{z-index: 1001; position: absolute; left: 10px; max-width: 302px; width: 300px; bottom: 16px; margin-left: calc(100% - 318px); background: rgb(255, 255, 255); border-radius: 8px;}`;
  return (
    <div className='st-map__legend'>
      <style>{styles}</style>
      <span className='st-typo st-typo--bold st-typo--secondary st-typo--caption-1'>
        <img width='21px' src={CropwiseImageryLogoIcon} alt='' />{' '}
        {t('general_map_multilayer_form_farmshots_vegetation_index')}
      </span>
      <div className='st-map__legend__colors'>
        {FARMSHOTS_COLORS_256.map((color) => (
          <div
            key={color}
            style={{
              backgroundColor: color,
              width: 246 / FARMSHOTS_COLORS_256.length,
              height: '12px'
            }}
          />
        ))}
      </div>
      <div className='st-map__legend__groups'>
        <span
          className='st-typo st-typo--caption-1'
          style={{ color: 'inherit', textAlign: 'inherit' }}
        >
          0
        </span>
        <span
          className='st-typo st-typo--caption-1'
          style={{ color: 'inherit', textAlign: 'inherit' }}
        >
          1
        </span>
      </div>
      <button
        className={`histogram-collapse-button${histogramOpen ? ' active' : ''}`}
        onClick={handleToggleHistogram}
      >
        <span
          className='st-typo st-typo--bold st-typo--secondary st-typo--paragraph-2'
          style={{
            color: ' rgb(112, 115, 116)',
            textAlign: 'inherit',
            display: 'flex'
          }}
        >
          <span style={{ paddingRight: '5px' }}>{t('general_map_multilayer_form_histogram')}</span>
          <SvgArrowUpGlyph fill='#707374' />
        </span>
      </button>
      {h.series.length === 0 && <SvgLoader />}
      {isEnabled && h.series.length !== 0 && histogramOpen && (
        <div className='histogram-container'>
          <div className='st-chart-container'>
            <div id='chart'>
              <ReactApexChart options={h.options} series={h.series} type='bar' />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

Histogram.propTypes = {
  state: PropTypes.any
};
